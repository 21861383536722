import { A11yModule } from '@angular/cdk/a11y';
import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonType = 'filled' | 'tonal' | 'outlined' | 'text';
export type ButtonVariation = 'primary' | 'secondary';

@Component({
	selector: 'csd-app-button',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, NgClass, A11yModule],
	templateUrl: './button.component.html',
	styleUrl: './button.component.scss',
})
export class ButtonComponent {
	active = input<boolean>(false);
	classList = input<string>('');
	iconClassList = input<string>('');
	disabled = input<boolean>(false);
	icon = input<string>('');
	size = input<ButtonSize>('medium');
	type = input<ButtonType>('filled');
	htmlType = input<string>('button');
	variation = input<ButtonVariation>('primary');
	cdkFocusInitial = input<boolean>(false);

	get componentClasses() {
		return `btn-${this.size()} btn-${this.type()} btn-${this.variation()}`;
	}
}
