<button
	class="csd-app-button"
	[class.active]="active()"
	[class.with-icon]="icon()"
	[ngClass]="[componentClasses, classList()]"
	[disabled]="disabled()"
	matRipple
	[type]="htmlType()"
>
	@if (icon()) {
		<mat-icon class="btn-icon px-2" [ngClass]="[iconClassList()]" aria-hidden="true" inline>{{
			icon()
		}}</mat-icon>
	}
	<span class="px-2 grow text-ellipsis overflow-hidden whitespace-nowrap">
		<ng-content></ng-content>
	</span>
</button>
